/* eslint-disable @typescript-eslint/no-unused-vars */
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';

import {
  AccessExtended,
  AccessRoleEnum,
  MeWithAccessRecordsApiResponse,
  PreferencesRecord,
  ProfileRecord,
  UserDocument,
  UserDocumentWithAccess,
} from '@youscience/user-service-common';

import { authService } from '@services/auth.api.service';

import { BRIGHTPATH_HOME_URL, AUTHENTICATION_BASE_URL } from '@constants/externalRoutes';

import { CurrentAccess, ImpersonatedBy, UserRoles } from '@interfaces/user';

// TODO - user-common-service needs to be updated to include the fields gradeLevel and plan
export interface ProfileGradeAndPlan {
  gradeLevel?: number;
  plan?: { now?: string; future?: string[] };
}
export type ProfileWithExtendedData = ProfileRecord & ProfileGradeAndPlan;

export type AuthSession<Y = AccessRoleEnum, T = boolean> = {
  currentAccess?: CurrentAccess;
  impersonatedBy?: ImpersonatedBy;
  isAuthenticated: T;
  isImpersonated: boolean;
  userData?: UserDocumentWithAccess;
  userType?: Y;
};

export interface AuthSessionContext {
  authSession: AuthSession;
  initializedUser: boolean;
  getMe: () => Promise<void>;
  resetAuthSession: () => void;
  setPreferences: (newPreference: string) => void;
  signIn: (param?: string, search?: string) => unknown;
  signOut: () => void;
}

const initialAuthSession: AuthSession = {
  currentAccess: undefined,
  impersonatedBy: undefined,
  isImpersonated: false,
  isAuthenticated: false,
  userData: undefined,
  userType: undefined,
};

const initialState: AuthSessionContext = {
  authSession: { ...initialAuthSession },
  initializedUser: false,
  getMe: () => Promise.resolve(),
  resetAuthSession: () => undefined,
  setPreferences: () => undefined,
  signIn: () => undefined,
  signOut: () => undefined,
};

export const useAuthStore = createWithEqualityFn<AuthSessionContext>()(
  devtools((set, get) => ({
    ...initialState,

    signIn: (pathname?: string, search?: string) => {
      window.location.replace(
        `${AUTHENTICATION_BASE_URL}?redirectUrl=${window.location.protocol}//${
          window.location.host
        }${pathname}${search}`,
      );
    },

    signOut: () => {
      window.location.replace(
        `${AUTHENTICATION_BASE_URL}/logout?logoutRedirectUrl=${window.location.protocol}//${window.location.host}`,
      );
    },

    resetAuthSession: () => {
      set((state) => ({ ...state, authSession: initialAuthSession, initializedUser: false }));
      window.location.assign(`${window.location.protocol}//${window.location.host}/`);
    },

    setPreferences: (newPreference: string) => {
      const { authSession } = get();

      let currentAccess: CurrentAccess;
      let isAuthenticated: boolean;
      let newAuthSession: AuthSession;
      let newPreferredAccess: AccessExtended | undefined;
      let userType: AccessRoleEnum;

      if (authSession.userData?.access.length) {
        newPreferredAccess = authSession.userData.access.find(
          (access: AccessExtended) => (access._id as unknown as string) === newPreference,
        );
      }

      if (newPreferredAccess) {
        currentAccess = {
          accessDocumentId: newPreferredAccess._id as unknown as string,
          constraintTags: newPreferredAccess?.user.grants ?? [],
          role: newPreferredAccess?.tenant?.permission?.role ?? UserRoles.Learner,
          tenantId: newPreferredAccess?.tenant.tenantId ?? '',
          tenantName: newPreferredAccess?.tenant.name ?? '',
          userId: newPreferredAccess?.user.userId ?? '',
          commonAppEnabled: newPreferredAccess?.tenant.productAvailability?.commonApp ?? false,
        };

        userType = currentAccess.role;
        isAuthenticated = true;

        newAuthSession = {
          ...authSession,
          // accessDocumentId: newPreferredAccess._id as unknown as string,
          currentAccess,
          isAuthenticated,
          userType,
          userData: {
            ...authSession.userData,
            fullName: newPreferredAccess.user.fullName,
            userId: newPreferredAccess.user.userId,
          } as UserDocument,
        } as AuthSession;

        set(() => ({ authSession: { ...newAuthSession } }));
      }
    },

    getMe: async () => {
      set((state) => ({ ...state }));

      try {
        const userData = (await authService.getUser()) as MeWithAccessRecordsApiResponse;

        const receivedPreference = (await authService.getUserPreferences()) as PreferencesRecord;

        if (userData) {
          let currentAccess: CurrentAccess;

          // Default to learner if no access
          currentAccess = {
            accessDocumentId: '',
            role: UserRoles.Learner,
            tenantId: '',
            constraintTags: [],
            tenantName: '',
            userId: '',
            commonAppEnabled: false,
          };

          if (userData?.access?.length > 0) {
            currentAccess = {
              accessDocumentId: userData.access?.[0]._id as unknown as string,
              constraintTags: userData.access?.[0].user.grants,
              role: userData.access?.[0].tenant.permission?.role ?? UserRoles.Learner,
              tenantId: userData.access?.[0].tenant.tenantId ?? '',
              tenantName: userData.access?.[0].tenant.name ?? '',
              userId: userData.access?.[0].user.userId ?? '',
              commonAppEnabled: userData.access?.[0].tenant.productAvailability?.commonApp ?? false,
            };
          } else {
            window.location.replace(BRIGHTPATH_HOME_URL);
          }

          set(() => ({
            authSession: {
              currentAccess,
              impersonatedBy: userData.impersonatedBy ?? undefined,
              isAuthenticated: true,
              isImpersonated: !!userData.impersonatedBy,
              userData,
              userType: currentAccess.role,
            } as AuthSession,
          }));

          if (
            (receivedPreference as unknown as string) &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            receivedPreference.currentAccess !== (userData.access[0]._id as unknown as string)
          ) {
            get().setPreferences(receivedPreference.currentAccess as unknown as string);
          }
        }
      } catch (err: unknown) {
        // TODO: set error
      } finally {
        set({ initializedUser: true });
      }
    },
  })),
  shallow,
);

export const signOutCallback: () => void = () => {
  const closure = useAuthStore.getState().signOut;

  return closure;
};

export const getMeCallback: () => Promise<void> = () => {
  const closure = useAuthStore.getState().getMe;

  return closure();
};

export const setPreferences: (preferredAccess: string) => void = (preferredAccess: string) => {
  const closure = useAuthStore.getState().setPreferences;

  return closure(preferredAccess);
};

export const getAuthSession: () => AuthSession | undefined = (): AuthSession | undefined => {
  const { authSession } = useAuthStore.getState();

  return authSession;
};

export const getCurrentAccess: () => CurrentAccess | undefined = () => {
  const closure = useAuthStore.getState().authSession.currentAccess;

  return closure;
};

export const resetAuthSession: () => void = () => {
  const closure = useAuthStore.getState().resetAuthSession;

  return closure();
};
