import { API_BASE_URL } from '@constants/externalRoutes';

import { Api } from './api.service';

export class BaseService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}

export const baseService = new BaseService();
