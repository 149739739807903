import { Link } from 'react-router-dom';
import { styled, MenuList } from '@mui/material';
import { coreThemeColors } from '@youscience/khaleesi';
import { APP_BAR_HEIGHT } from '@youscience/brightpath-header';

import { propsFilter } from '@utils/propsFilter';

export const sxStyles = {
  toggleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '0.5rem',
  },
};

export const StyledSidebarList = styled(MenuList)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: `${APP_BAR_HEIGHT + 8}px 1rem 0 1rem`,
  backgroundColor: '#F7F7F7',

  'li.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',

    '&:hover': {
      cursor: 'default',
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const StyledSidebarItem = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ isSelected, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.25rem 0.5rem',
  borderRadius: '4px',
  color: theme.palette.common.black,
  backgroundColor: isSelected ? coreThemeColors.discoveryPrimaryColors.primary.outlinedHoverBackground : undefined,

  '& > p:first-of-type': {
    marginLeft: '0.75rem',
  },

  '&:hover': {
    backgroundColor: !isSelected && theme.palette.grey[100],
  },
}));

export const StyledRemovedTaskBtn = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ isActive, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0.25rem 0.5rem',
  backgroundColor: isActive ? coreThemeColors.discoveryPrimaryColors.primary.outlinedHoverBackground : undefined,

  '& > svg:first-of-type': {
    fill: theme.palette.grey['600'],
    marginRight: '0.75rem',
  },

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: !isActive && theme.palette.grey[100],
  },
}));

export const StyledToggle = styled('div', {
  shouldForwardProp: propsFilter(['isSidebarOpen']),
})<{ isSidebarOpen?: boolean }>(({ isSidebarOpen, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '40px',

  '& > svg:first-of-type': {
    fill: theme.palette.grey['600'],
    transform: isSidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}));
