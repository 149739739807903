import { createWithEqualityFn } from 'zustand/traditional';
import {
  EnrolledSchool,
  Major,
  School,
  SchoolWithScholarships,
  SetSchoolInfo,
  ScholarshipCard,
} from '@youscience/college-service-common';

import { SLIDER_ITEMS_LIMIT } from '@constants';

import useNotificationStore from '@store/NotificationStore';

import { baseService } from '@services/base.service';

export interface BaseHomePageData {
  careerFitMajors: Major[];
  careerFavoriteMajors: Major[];
}

export interface TenantAdminAssignment {
  ipedsId: number;
  school: SchoolWithScholarships;
}

export interface HomePageStore {
  adminHomePageData: TenantAdminAssignment;
  updateAdminHomePageData: (data: SetSchoolInfo, notifyMessage: string) => Promise<void>;

  baseHomePageData: BaseHomePageData | null;
  landingPageSchoolData: EnrolledSchool;
  isBaseHomePageDataLoading: boolean;

  getBaseHomePageData: () => Promise<void>;
  getLandingPageData: () => Promise<void>;
  setLandingPageData: (landingPageData?: EnrolledSchool) => Promise<void>;
  setAdminHomePageData: (data: TenantAdminAssignment) => void;
}

const initialState = {
  adminHomePageData: {} as TenantAdminAssignment,
  baseHomePageData: {} as BaseHomePageData,
  landingPageSchoolData: {} as EnrolledSchool,
  isBaseHomePageDataLoading: false,
};

export const useHomePageStore = createWithEqualityFn<HomePageStore>()(
  (set, get) => ({
    ...initialState,

    getBaseHomePageData: async () => {
      set({ isBaseHomePageDataLoading: true });

      const careerFitMajors = await baseService.getAsync<{ majors: Major[] }>(
        `/majors?limit=${SLIDER_ITEMS_LIMIT}&careerFit=true`,
      );
      const careerFavoriteMajors = await baseService.getAsync<{ majors: Major[] }>(
        `/majors?limit=${SLIDER_ITEMS_LIMIT}&careerFavorite=true`,
      );

      set({
        baseHomePageData: {
          careerFitMajors: careerFitMajors.majors,
          careerFavoriteMajors: careerFavoriteMajors.majors,
        },
        isBaseHomePageDataLoading: false,
      });
    },

    getLandingPageData: async () => {
      const enrolledSchoolData = await baseService.getAsync<EnrolledSchool>('/user/enrolled');

      set({
        landingPageSchoolData: enrolledSchoolData,
      });
    },

    setLandingPageData: async (landingPageData?: EnrolledSchool) => {
      if (landingPageData?.scholarships && landingPageData?.scholarships?.length < 9) {
        const similarScholarships = await baseService.getAsync<ScholarshipCard[]>(
          `/scholarship-cards/${landingPageData.id}/similar`,
        );

        set({
          landingPageSchoolData: {
            ...landingPageData,
            scholarships: [...landingPageData.scholarships, ...similarScholarships],
          },
        });
      } else {
        set({ landingPageSchoolData: landingPageData ?? ({} as EnrolledSchool) });
      }
    },

    setAdminHomePageData: (data: TenantAdminAssignment) => {
      set({ adminHomePageData: data ?? {} });
    },

    updateAdminHomePageData: async (data: SetSchoolInfo, notifyMessage: string) => {
      const {
        adminHomePageData: { ipedsId },
        setAdminHomePageData,
      } = get();

      const updatedSchool = await baseService.patchAsync<School, SetSchoolInfo>(`/school-info/${ipedsId}`, data);

      setAdminHomePageData({ ipedsId, school: updatedSchool });

      useNotificationStore.getState().notify({
        title: 'Success!',
        message: notifyMessage,
        severity: 'success',
      });
    },
  }),
  Object.is,
);
