import { createWithEqualityFn } from 'zustand/traditional';

import { ApplicationType, ApplicationStatus, ApplyingCollegeWithSchool } from '@youscience/college-service-common';

import { INITIAL_PAGE_FILTERS } from '@constants/applyingSchoolsPlanner';
import { ORDER_BY_EARLIEST } from '@constants';

import { baseService } from '@services/base.service';
import { notify, useNotificationStore } from './notificationStore';

export interface ApplyingSchoolsFilters {
  applicationType: ApplicationType | string;
  applicationStatus: ApplicationStatus | string;
}

interface ApplyingSchoolsPlannerStore {
  appliedSchools: ApplyingCollegeWithSchool[];
  pageFilters: ApplyingSchoolsFilters;
  orderBy: string;

  getAppliedSchool: () => Promise<void>;
  deleteAppliedSchool: (schoolId: number) => Promise<void>;
  editAppliedSchool: (data: Partial<ApplyingCollegeWithSchool>, schoolName: string) => Promise<void>;

  setPageFilters: (filters: Partial<ApplyingSchoolsFilters>) => void;
  setOrderBy: (order: string) => void;
}

const initialState = {
  appliedSchools: [],
  pageFilters: INITIAL_PAGE_FILTERS,
  orderBy: ORDER_BY_EARLIEST,
};

export const useApplyingSchoolsPlannerStore = createWithEqualityFn<ApplyingSchoolsPlannerStore>()(
  (set, get) => ({
    ...initialState,

    getAppliedSchool: async () => {
      const appliedSchools = await baseService.getAsync<ApplyingCollegeWithSchool[]>('/user/applying-college');

      set({
        appliedSchools,
      });
    },

    editAppliedSchool: async (applyingCollegeWithSchool: Partial<ApplyingCollegeWithSchool>, schoolName) => {
      const filterFalsyValues = (obj: Record<string, unknown> | undefined) => {
        if (!obj) return {};

        const newObj = { ...obj };

        Object.keys(newObj).forEach((key) => !newObj[key] && delete newObj[key]);

        return newObj;
      };

      const applicationDataWithoutFalsyValues = filterFalsyValues(applyingCollegeWithSchool.application);
      const dataWithoutFalsyValues = filterFalsyValues(applyingCollegeWithSchool);

      await baseService.patchAsync(`/user/applying-college`, {
        ...dataWithoutFalsyValues,
        application: applicationDataWithoutFalsyValues,
      });

      get().getAppliedSchool();

      notify({
        title: `Changes to the application information for ${schoolName} saved.`,
        message: '',
        severity: 'success',
      });
    },

    deleteAppliedSchool: async (schoolId: number) => {
      const { appliedSchools } = get();

      await baseService.deleteAsync(`/user/applying-college/${schoolId}`);

      const filteredSchools = appliedSchools.filter((school) => school.id !== schoolId);

      set({
        appliedSchools: filteredSchools,
      });

      useNotificationStore.getState().notify({
        title: 'School was removed.',
        message: 'You can add it back to your list.',
        severity: 'success',
      });
    },

    setPageFilters: (filters: Partial<ApplyingSchoolsFilters>) => {
      set({
        pageFilters: { ...get().pageFilters, ...filters },
      });
    },

    setOrderBy: (orderBy: string) => {
      set({
        orderBy,
      });
    },
  }),
  Object.is,
);
