import { ComponentType, useState } from 'react';

import { AllSchoolConnection } from '@components/OptIn/components/AllSchoolConnection';
import { ShareInfo } from '@components/OptIn/components/ShareInfo';
import { OptInContactForm } from '@components/OptIn/components/OptInContactForm/OptInContactForm';

import { InitialModalsStatus } from '@interfaces/optIn';

export interface WithAllSchoolConnectionModalProps {
  onOpenAllSchoolConnection: () => void;
}

export type AllSchoolConnectionModalProps = Partial<WithAllSchoolConnectionModalProps>;

const initialModalsStatus: Partial<InitialModalsStatus> = {
  allSchoolConnection: false,
  optInContactForm: false,
  shareInfo: false,
};

export const withAllSchoolConnectionModal =
  <T extends AllSchoolConnectionModalProps>(WrappedComponent: ComponentType<T>) =>
  (props: T & AllSchoolConnectionModalProps) => {
    const [activeModalStatus, setActiveModalStatus] = useState(initialModalsStatus);

    const setActiveModal = (field?: keyof InitialModalsStatus) => {
      if (field) {
        setActiveModalStatus({ ...initialModalsStatus, [field]: true });
      } else {
        setActiveModalStatus({ ...initialModalsStatus });
      }
    };

    const handleOpenAllSchoolConnection = () => {
      setActiveModal('allSchoolConnection');
    };

    return (
      <>
        <WrappedComponent {...(props as T)} onOpenAllSchoolConnection={handleOpenAllSchoolConnection} />

        {activeModalStatus.allSchoolConnection && (
          <AllSchoolConnection
            isOpenAllSchoolConnection={activeModalStatus.allSchoolConnection}
            setActiveModal={setActiveModal}
          />
        )}

        {activeModalStatus.shareInfo && (
          <ShareInfo
            isOpenShareInfo={activeModalStatus.shareInfo}
            onCloseShareInfo={setActiveModal}
            onOpenPreviousStep={handleOpenAllSchoolConnection}
          />
        )}

        {activeModalStatus.optInContactForm && (
          <OptInContactForm
            isOpenOptInContactForm={activeModalStatus.optInContactForm}
            onGoBack={handleOpenAllSchoolConnection}
            onGoNext={handleOpenAllSchoolConnection}
          />
        )}
      </>
    );
  };
