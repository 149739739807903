import { useState } from 'react';
import { Season } from '@youscience/college-service-common';
import { Theme, useMediaQuery } from '@mui/material';

import { ALL_TYPES_FILTER_VALUE, FILTER_TAGS_COLORS, INITIAL_FILTERS } from '@constants/educationPlanner';

import { useEducationPlannerStore } from '@store/educationPlannerStore';

export const usePlannerSidebar = () => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobileScreen);

  const { userPlanning, setEducationPlannerData } = useEducationPlannerStore((state) => state);

  const isShowRemovedTasks = userPlanning.tasks && userPlanning.tasks.filter((task) => task.isDeleted).length > 0;

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleSidebarClick = () => {
    setEducationPlannerData({
      currentSeason: Season.FALL,
      pageFilters: INITIAL_FILTERS,
      tagColor: FILTER_TAGS_COLORS[ALL_TYPES_FILTER_VALUE],
      isTaskArchivePage: false,
    });
  };

  const handleOpenTaskArchivePage = () => {
    setEducationPlannerData({
      currentSeason: null,
      isTaskArchivePage: true,
      pageFilters: INITIAL_FILTERS,
      tagColor: FILTER_TAGS_COLORS[ALL_TYPES_FILTER_VALUE],
    });
  };

  return {
    isSidebarOpen,
    isShowRemovedTasks,
    toggleSidebar,
    handleSidebarClick,
    handleOpenTaskArchivePage,
  };
};
