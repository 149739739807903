const EN_US_LOCALE = 'en-US';

// adding a comma to separate the number into digits and add currency. like 10000 => $10,000
export const moneyFormatter = new Intl.NumberFormat(EN_US_LOCALE, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

// adding a comma to separate the number into digits. like 10000 => 10,000
export const separateDigitsByComma = new Intl.NumberFormat(EN_US_LOCALE, {
  style: 'decimal',
  maximumFractionDigits: 0,
});

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const getMonthName = (monthNumber: number) => {
  const date = new Date();

  date.setMonth(monthNumber - 1);

  return date.toLocaleString(EN_US_LOCALE, {
    month: 'short',
  });
};

export const readableDate = (date: Date) => {
  const dateArray = date.toDateString().split(' ');

  return `${dateArray[1]} ${dateArray[2]}, ${dateArray[3]}`;
};

export const formatDateWithFallback = (date: string | undefined | null | Date, fallback = '') => {
  if (!date) {
    return fallback;
  }

  const formattedDate = new Date(date);

  return formattedDate.toLocaleDateString(EN_US_LOCALE, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const formatDateFromYMDString = (date: string | undefined | null, fallback = '', delimiter: string = '-') => {
  if (!date) {
    return fallback;
  }

  const dateDays = date.split(delimiter).map(Number);
  const formattedDate = new Date(dateDays[0], dateDays[1] - 1, dateDays[2]);

  if (isNaN(formattedDate.getTime())) {
    return fallback;
  }

  return formatDateWithFallback(formattedDate);
};
