export const getOptions = (list: unknown[], labelKey: string, valueKey: string) => {
  return (list as Record<string, string>[]).map((listItem) => ({
    label: listItem[labelKey],
    value: listItem[valueKey].toString(),
  }));
};

export const validateEmail = (email: string) => {
  const reg =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  return reg.test(email?.toLowerCase());
};
