import { APP_BAR_HEIGHT, Footer } from '@youscience/brightpath-header';
import { styled } from '@mui/material';

export const StyledLayoutWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isRowDirection',
})<{ isRowDirection: boolean }>(({ isRowDirection }) => ({
  display: 'flex',
  flexDirection: isRowDirection ? 'row' : 'column',
  paddingBottom: isRowDirection ? '3.375rem' : 0,
}));

export const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'isNeedToAddPaddingTop',
})<{ isNeedToAddPaddingTop: boolean }>(({ isNeedToAddPaddingTop }) => ({
  flex: 1,
  paddingTop: isNeedToAddPaddingTop ? 0 : APP_BAR_HEIGHT,
}));

export const StyledLoaderWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16%',

  '& > h6:first-of-type': {
    marginTop: '1.5rem',
  },
}));

export const StyledFooter = styled(Footer)(() => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1200,
}));
