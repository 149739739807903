import { styled, Theme } from '@mui/material';
import { themeDefaults } from '@youscience/khaleesi';

export const sxStyles = {
  header: {
    padding: '0 1rem 0.625rem',
  },
  schoolName: {
    marginBottom: '1.5rem',
    fontWeight: 400,
    color: themeDefaults?.palette?.text?.disabled,
  },
  body: (theme: Theme) => ({
    padding: '0.5rem 1rem',
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
  }),
};

export const StyledChangeEnrollment = styled('li')(() => ({
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    cursor: 'pointer',
  },

  '& > svg:first-of-type': {
    marginRight: '0.75rem',
  },
}));
