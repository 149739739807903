import { IpedsCardInfo, TenantSharing } from '@youscience/college-service-common';
import { Option } from '@interfaces';

export interface SchoolByState {
  id: number;
  city: string;
  name: string;
  zip: string;
}

export const STEPS = {
  step1: 1,
  step2: 2,
};

export interface SchoolSelectionData {
  activeStep?: number;
  state?: Option | null;
  school?: Option | null;
  schoolListOptions?: Option[];
  schoolPreviewInfo: IpedsCardInfo;
}

export interface SchoolSelectionStore {
  schoolSelectionData: SchoolSelectionData;
  isPreviewCardDataLoading: boolean;
  getSchoolListByState: () => void;
  getSchoolInfo: () => void;
  setSchoolSelectionData: (data: Partial<SchoolSelectionData>) => void;
  saveSchoolSelection: () => Promise<void>;
  deleteSchoolSelection?: (schoolId: number) => Promise<void>;
}

export interface TenantSharingSchool extends TenantSharing {
  collegeName: string;
}
