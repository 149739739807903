import { styled } from '@mui/material';
import { CoreDialogContent } from '@youscience/khaleesi';

export const sxStyles = {
  dialogWrap: {
    '.MuiPaper-root': {
      maxWidth: '600px',
      width: '100%',
      borderRadius: '12px',
    },
  },
  subtitle: {
    margin: '1rem 0',
  },
};

export const StyledDialogContent = styled(CoreDialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  maxWidth: '540px',
  padding: '2.5rem 1rem',

  '& > h3:last-of-type': {
    textAlign: 'center',
  },

  '& > ul:first-of-type': {
    paddingLeft: '2rem',
    margin: '0.5rem 0 0',
  },
}));
