import { HTMLAttributes, ReactNode, SyntheticEvent } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams,
  FormHelperText,
  TextField,
} from '@mui/material';
import { CoreBox, CoreTypography } from '@youscience/khaleesi';

import { AutocompleteOption } from '@interfaces';

import NoOptionsIcon from '@images/no-options.svg?react';

import { sxStyles, StyledAutocompleteMenuItem, StyledPaper } from './Autocomplete.styles';

export const renderNoOptionsText = () => {
  return (
    <CoreBox sx={sxStyles.noOptionsWrap}>
      <NoOptionsIcon />

      <CoreTypography sx={sxStyles.noOptionsText} component="div">
        No search result
      </CoreTypography>
    </CoreBox>
  );
};

interface AutocompleteProps {
  // eslint-disable-next-line
  control: Control<any>;
  name: string;
  label: string;
  isDisabled?: boolean;
  clearOnBlur?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
  onSelectOption?: (event: SyntheticEvent, option: AutocompleteOption) => void;
  isMultiple?: boolean;
  limitTags?: number;
  options: AutocompleteOption[];
  onChange?: (name: string, value: AutocompleteOption | readonly AutocompleteOption[] | null) => void;
}

export const Autocomplete = (props: AutocompleteProps) => {
  const { control, name, isMultiple, isDisabled, limitTags, options, label, onChange, ...rest } = props;

  const defaultValue = isMultiple ? [] : null;

  const renderInput = (params: AutocompleteRenderInputParams, fieldState: ControllerFieldState) => {
    if (isMultiple) {
      return (
        <>
          <TextField {...params} label={label} placeholder="" sx={sxStyles.box} />

          {fieldState.error && (
            <FormHelperText error>
              {(
                fieldState?.error as unknown as {
                  label: FieldError;
                  message: string;
                }[]
              ).map((err) => err.label.message)}
            </FormHelperText>
          )}
        </>
      );
    }

    return (
      <>
        <TextField {...params} label={label} placeholder="" sx={sxStyles.box} />

        {fieldState.error && (
          <FormHelperText error>
            {(
              fieldState?.error as unknown as {
                label: FieldError;
                message: string;
              }
            )?.label
              ? (
                  fieldState.error as unknown as {
                    label: FieldError;
                    message: string;
                  }
                ).label.message
              : fieldState.error.message}
          </FormHelperText>
        )}
      </>
    );
  };

  const getSelectedOption = (option: unknown, value: unknown) => {
    if (!option && value) {
      return true;
    }

    return (
      (option as AutocompleteOption).value === (typeof value === 'string' ? value : (value as AutocompleteOption).value)
    );
  };

  const renderOption = (optionProps: HTMLAttributes<HTMLLIElement>, option: AutocompleteOption) => {
    return (
      <StyledAutocompleteMenuItem {...optionProps} key={option.value} disabled={option.isDisabled}>
        <span>{option.label}</span>
      </StyledAutocompleteMenuItem>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const handleSelect = (
          event: SyntheticEvent,
          value: AutocompleteOption | readonly AutocompleteOption[] | null,
        ) => {
          if (onChange) {
            onChange(name, value);

            return;
          }

          if (!value) {
            field.onChange(); // to remove the value
          } else {
            field.onChange(value);
          }
        };

        return (
          <MuiAutocomplete
            multiple={isMultiple}
            limitTags={isMultiple ? limitTags : undefined}
            value={field.value as AutocompleteOption | AutocompleteOption[]}
            PaperComponent={StyledPaper}
            onChange={handleSelect}
            size="small"
            renderInput={(params) => renderInput(params, fieldState)}
            renderOption={renderOption}
            disabled={isDisabled}
            options={options}
            isOptionEqualToValue={getSelectedOption}
            clearIcon={null}
            noOptionsText={renderNoOptionsText()}
            {...rest}
          />
        );
      }}
    />
  );
};
