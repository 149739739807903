import { Outlet } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { CoreTypography } from '@youscience/khaleesi';
import { Footer } from '@youscience/brightpath-header';

import { useSidebarInit } from '@hooks/useSidebarInit';

import { Header } from './components';

import { StyledLayoutWrap, StyledMain, StyledLoaderWrap, StyledFooter } from './Layout.styles';

export const Layout = () => {
  const { isMobileScreen, isMobileSidebarRoute, isInitialDataLoading, mobileSidebar, desktopSidebar } =
    useSidebarInit();

  return (
    <>
      <Header />

      <StyledLayoutWrap isRowDirection={!isMobileSidebarRoute}>
        {isMobileScreen ? mobileSidebar : desktopSidebar}

        <StyledMain isNeedToAddPaddingTop={isMobileSidebarRoute}>
          {isInitialDataLoading ? (
            <StyledLoaderWrap>
              <CircularProgress size={50} thickness={3} />

              <CoreTypography variant="h6">Getting everything ready...</CoreTypography>
            </StyledLoaderWrap>
          ) : (
            <Outlet />
          )}
        </StyledMain>
      </StyledLayoutWrap>

      {isMobileScreen ? <Footer /> : <StyledFooter />}
    </>
  );
};
