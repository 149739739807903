import { styled, Theme, Alert } from '@mui/material';
import { CoreDialogActions } from '@youscience/khaleesi';

export const sxStyles = {
  dialogWrap: {
    '.MuiPaper-root': {
      borderRadius: '12px',
    },
  },
  title: {
    maxWidth: '600px',
    padding: '2rem 1.5rem 0.5rem 1.5rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  schoolListWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  schoolList: {
    margin: '1.5rem 0 1 rem 0',
  },
  listTitle: (theme: Theme) => ({
    marginRight: 0,
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    color: theme.palette.grey['700'],
  }),
  listItem: (theme: Theme) => ({
    marginRight: 0,
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
  }),
  checkBox: {
    marginRight: '1.5rem',
  },
  shareInfo: (theme: Theme) => ({
    margin: '1rem 0',

    '& > span:first-of-type': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  }),
  leftActions: {
    display: 'flex',
    gap: '0.5rem',
  },
  rightActions: {
    display: 'flex',
    width: '216px',
    gap: '0.5rem',

    '& > div:first-of-type': {
      marginRight: '0.5rem',
    },
  },
};

export const StyledAlert = styled(Alert)(() => ({
  display: 'flex',
  marginTop: '1rem',
  alignItems: 'start',
  maxWidth: '100%',
  minHeight: 'auto',
}));

export const StyledDialogFooter = styled(CoreDialogActions)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'start',
  justifyContent: 'space-between',
  padding: '1.5rem',
  minHeight: '96px',
  borderTop: `1px solid ${theme.palette.grey['300']}`,

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5rem 1rem',
  },
}));

export const StyledSchoolCounter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '110px',
  width: '100%',
  minHeight: '40px',
  backgroundColor: theme.palette.grey['300'],
}));
