/* eslint-disable @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from 'axios';
import { omit } from 'lodash-es';
import { FindUsersQuery, UserDocument, UserDocumentWithAccess } from '@youscience/user-service-common';

import { PreferredAccess } from '@interfaces/user';

import { GUS_BASE_URL } from '@constants/externalRoutes';

import { Api } from './api.service';

class AuthService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: GUS_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public getUser = (): Promise<void | UserDocumentWithAccess> => {
    return this.get<UserDocument, AxiosResponse<UserDocumentWithAccess>>('/me').then(this.success).catch(this.error);
  };

  public getUsers = async (query: FindUsersQuery): Promise<UserDocument[]> => {
    const roles = query.roles?.join(',') as string;

    return this.getAsync<UserDocument[]>(`/users${roles.length ? `?roles=${roles}` : ``}`, {
      params: { ...omit(query, 'roles') },
    });
  };

  public getUserPreferences = (): Promise<void | PreferredAccess> => {
    return this.get<PreferredAccess, AxiosResponse<PreferredAccess>>('/me/preferences')
      .then(this.success)
      .catch(this.error);
  };

  public updateUserPreferences = (preferredAccess: PreferredAccess): Promise<void | PreferredAccess> => {
    return this.patch<PreferredAccess, PreferredAccess, AxiosResponse<PreferredAccess>>(
      '/me/preferences',
      preferredAccess,
    )
      .then(this.success)
      .catch(this.error);
  };
}

export const authService = new AuthService();
