import { CoreTypography, CoreBox } from '@youscience/khaleesi';
import { Divider } from '@mui/material';

import { ROUTES } from '@constants/ROUTES';
import { ECP_APPS_URL } from '@constants/externalRoutes';
import { ECP_HASH } from '@constants';

import { useRecommenderSpaceSidebar } from '@hooks/useRecommenderSpaceSidebar';

import SchoolHatIcon from '@images/school-hat.svg?react';
import FileIcon from '@images/file.svg?react';
import ChevronBottomIcon from '@images/chevron-bottom.svg?react';

import { sxStyles, StyledSidebarList, StyledSidebarItem, StyledToggle } from './MobileRecommenderSpaceSidebar.styles';

export const MobileRecommenderSpaceSidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useRecommenderSpaceSidebar();

  return (
    <StyledSidebarList data-testid="sidebarList">
      {isSidebarOpen ? (
        <>
          {location.hash === ECP_HASH ? (
            <StyledSidebarItem to={`${ECP_APPS_URL}/learner`}>
              <SchoolHatIcon />

              <CoreTypography variant="body2">Education & Career Plan</CoreTypography>
            </StyledSidebarItem>
          ) : null}

          <StyledSidebarItem
            to={`${ROUTES.RECOMMENDER_SPACE}${location.hash}`}
            isSelected={location.pathname.endsWith(ROUTES.RECOMMENDER_SPACE)}
          >
            <FileIcon />

            <CoreTypography variant="body2">Common App applicants</CoreTypography>
          </StyledSidebarItem>

          <Divider />
        </>
      ) : null}

      <CoreBox sx={sxStyles.toggleWrap} onClick={toggleSidebar}>
        <StyledToggle isSidebarOpen={isSidebarOpen}>
          <ChevronBottomIcon />
        </StyledToggle>
      </CoreBox>
    </StyledSidebarList>
  );
};
