import { useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

export const useRecommenderSpaceSidebar = () => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobileScreen);
  const [isCommonAppSidebarOpen, setIsCommonAppSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const toggleCommonAppSidebar = () => {
    setIsCommonAppSidebarOpen((prevState) => !prevState);
  };

  return {
    isSidebarOpen,
    toggleSidebar,
    isCommonAppSidebarOpen,
    toggleCommonAppSidebar,
  };
};
