import { createWithEqualityFn } from 'zustand/traditional';
import { SchoolFavorite, FavoriteMajor, UserPreferences, ScholarshipCard } from '@youscience/college-service-common';

import { updateUserPreferences } from '@api/userPreferences';

export interface UserPreferencesStore {
  userPreferences: UserPreferences;
  schoolFavorites: SchoolFavorite[];
  majorFavorites: FavoriteMajor;
  scholarshipFavorites: ScholarshipCard[];

  setUserPreferences: (userPreferences?: UserPreferences) => void;
  saveUserSharingPreferences: (data: Partial<UserPreferences>) => Promise<void>;
}

const initialState = {
  userPreferences: {} as UserPreferences,
  schoolFavorites: [] as SchoolFavorite[],
  majorFavorites: { cipCodes: [], cipCodeInformation: [] } as FavoriteMajor,
  scholarshipFavorites: [] as ScholarshipCard[],
} as UserPreferencesStore;

export const useUserPreferencesStore = createWithEqualityFn<UserPreferencesStore>()(
  (set) => ({
    ...initialState,

    setUserPreferences: (userPreferences?: UserPreferences) => {
      if (!userPreferences) {
        return;
      }

      const schoolFavorites =
        userPreferences.favorites?.colleges
          ?.filter((favorite) => favorite.isActive)
          .sort((a, b) => a.school.directory.name.localeCompare(b.school.directory.name)) ?? [];

      const majorFavorites = userPreferences.favorites?.majors ?? { cipCodes: [], cipCodeInformation: [] };

      const scholarshipFavorites = userPreferences.favorites?.scholarships ?? [];

      set({
        userPreferences,
        schoolFavorites,
        majorFavorites,
        scholarshipFavorites,
      });
    },

    saveUserSharingPreferences: async (data: Partial<UserPreferences>) => {
      return updateUserPreferences(data);
    },
  }),
  Object.is,
);
