import { CircularProgress, CircularProgressProps, styled } from '@mui/material';
import { brightpathTheme } from '@youscience/theme';

interface StyledCircularProgressProps extends CircularProgressProps {
  buttonVariant?: 'text' | 'outlined' | 'contained';
}

export const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'buttonVariant',
})<StyledCircularProgressProps>(({ buttonVariant }) => ({
  color: buttonVariant === 'contained' ? brightpathTheme.palette.secondary.main : 'inherit',
}));
