import { MenuItem, styled, Theme } from '@mui/material';
import { CorePaper } from '@youscience/khaleesi';

export const sxStyles = {
  box: (theme: Theme) => ({
    backgroundColor: theme.palette.common.white,
  }),
  noOptionsWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  noOptionsText: {
    marginLeft: '0.75rem',
    marginTop: '0.25rem',
  },
};

export const StyledPaper = styled(CorePaper)(() => ({
  borderRadius: '0 0 0.25rem 0.25rem',
}));

export const StyledAutocompleteMenuItem = styled(MenuItem)(() => ({
  span: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
