import { styled } from '@mui/material';
import { CoreDialogContent } from '@youscience/khaleesi';

export const sxStyles = {
  wrap: {
    '.MuiPaper-root': {
      maxWidth: '590px',
      width: '100%',
      borderRadius: '12px',
    },
  },
  title: {
    marginBottom: '1.5rem',
  },
};

export const StyledDialogContent = styled(CoreDialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  padding: '2.5rem 0',
  maxWidth: '500px',
  textAlign: 'center',
}));
