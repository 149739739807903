import { Link } from 'react-router-dom';
import { styled, Theme, CSSObject, Drawer, MenuList } from '@mui/material';
import { coreThemeColors } from '@youscience/khaleesi';
import { APP_BAR_HEIGHT } from '@youscience/brightpath-header';

import { propsFilter } from '@utils/propsFilter';

const FOOTER_HEIGHT = '3.375rem';

export const sxStyles = {
  toggleWrap: (theme: Theme) => ({
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
  }),
  sidebarTitle: (theme: Theme) => ({
    padding: '0.5rem',
    color: theme.palette.grey['600'],
  }),
  topSidebarActions: {
    padding: '1rem 0.5rem',
  },
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: '18.5rem',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderRadius: 0,
  paddingBottom: FOOTER_HEIGHT,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '4rem',
  paddingBottom: FOOTER_HEIGHT,
});

export const StyledSidebarWrap = styled(Drawer, { shouldForwardProp: propsFilter(['open']) })(({ theme, open }) => ({
  width: '18.5rem',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  '.MuiDrawer-paper': {
    marginTop: APP_BAR_HEIGHT,
    backgroundColor: 'inherit',
    borderRadius: 0,
    boxShadow: 'none',
    overflow: 'visible !important',
  },

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),

  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyledToggle = styled('div', {
  shouldForwardProp: propsFilter(['isSidebarOpen', 'isSelected']),
})<{ isSidebarOpen?: boolean }>(({ isSidebarOpen, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '40px',

  '&:hover': {
    cursor: 'pointer',
    background: theme.palette.grey[100],
  },

  '& > svg:first-of-type': {
    fill: theme.palette.grey['600'],
    transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)',
  },
}));

export const StyledSidebarList = styled(MenuList, {
  shouldForwardProp: propsFilter(['isSidebarOpen', 'isSelected']),
})<{ isSidebarOpen?: boolean }>(({ isSidebarOpen, theme }) => ({
  height: '100%',

  li: {
    width: isSidebarOpen && '240px',
  },

  'li.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: isSidebarOpen ? '4px' : '50%',

    '&:hover': {
      cursor: 'default',
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const StyledSidebarItem = styled(Link, {
  shouldForwardProp: propsFilter(['isSidebarOpen', 'isSelected']),
})<{ isSelected?: boolean; isSidebarOpen: boolean }>(({ isSelected, isSidebarOpen, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isSidebarOpen ? 'start' : 'center',
  padding: ' 0.5rem',
  borderRadius: '4px',
  color: theme.palette.common.black,
  backgroundColor: isSelected ? coreThemeColors.discoveryPrimaryColors.primary.outlinedHoverBackground : undefined,

  '& > p:first-of-type': {
    marginLeft: '0.75rem',
  },

  '&:hover': {
    backgroundColor: !isSelected && theme.palette.grey[100],
  },
}));

export const StyledRemovedTaskBtn = styled('div', {
  shouldForwardProp: propsFilter(['isActive', 'isSidebarOpen']),
})<{ isActive: boolean; isSidebarOpen: boolean }>(({ isActive, isSidebarOpen, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isSidebarOpen ? 'start' : 'center',
  maxWidth: isSidebarOpen ? '278px' : '48px',
  borderRadius: '4px',
  width: '100%',
  padding: ' 0.5rem',
  backgroundColor: isActive ? coreThemeColors.discoveryPrimaryColors.primary.outlinedHoverBackground : undefined,

  '& > svg:first-of-type': {
    fill: theme.palette.grey['600'],
    marginRight: isSidebarOpen ? '0.75rem' : 0,
  },

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: !isActive && theme.palette.grey[100],
  },
}));

export const StyledBottomSidebarActions = styled('div')(({ theme }) => ({
  padding: '1rem 0.5rem 0 0.5rem',
  width: '100%',
  position: 'absolute',
  bottom: '1rem',
  borderTop: `1px solid ${theme.palette.grey['300']}`,
}));
