import { styled } from '@mui/material';

import { CoreBox } from '@youscience/khaleesi';

export const StyledManageSharing = styled(CoreBox)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.75rem 1rem',

  '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.04)' },

  '& > svg:first-of-type': {
    marginRight: '0.75rem',
  },
}));
