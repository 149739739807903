import { COMMON_APP_URL } from '@constants/externalRoutes';

import { Api } from './api.service';

export class CommonAppService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: COMMON_APP_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}

export const commonAppService = new CommonAppService();
