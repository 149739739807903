/* eslint-disable max-len */
import { Season, TaskStatus, TaskTags } from '@youscience/college-service-common';

import { ATTENDING_YEAR } from '@constants/index';

import FallIcon from '@images/fall.svg?react';
import WinterIcon from '@images/winter.svg?react';
import SpringIcon from '@images/spring.svg?react';
import SummerIcon from '@images/summer.svg?react';

type Severity = 'success' | 'error' | 'info' | 'warning';

export const ALL_TYPES_FILTER_VALUE = 'All types';

export const FILTER_TAGS_COLORS: { [key: string]: string } = {
  [ALL_TYPES_FILTER_VALUE]: '#464a4f',
  [TaskTags.COLLEGE]: '#8E2880',
  [TaskTags.EXTRACURRICULAR]: '#D32F2F',
  [TaskTags.PREPARATION]: '#ED6C02',
  [TaskTags.FINANCIAL_AID]: '#0C938F',
  [TaskTags.TESTING]: '#0288D1',
} as const;

export const FILTER_TAGS_DATA = [
  {
    value: ALL_TYPES_FILTER_VALUE,
    color: '#464a4f',
  },
  {
    value: TaskTags.COLLEGE,
    color: FILTER_TAGS_COLORS[TaskTags.COLLEGE],
  },
  {
    value: TaskTags.EXTRACURRICULAR,
    color: FILTER_TAGS_COLORS[TaskTags.EXTRACURRICULAR],
  },
  {
    value: TaskTags.PREPARATION,
    color: FILTER_TAGS_COLORS[TaskTags.PREPARATION],
  },
  {
    value: TaskTags.FINANCIAL_AID,
    color: FILTER_TAGS_COLORS[TaskTags.FINANCIAL_AID],
  },
  {
    value: TaskTags.TESTING,
    color: FILTER_TAGS_COLORS[TaskTags.TESTING],
  },
];

export const INITIAL_FILTERS = {
  status: ALL_TYPES_FILTER_VALUE,
  tag: ALL_TYPES_FILTER_VALUE,
};

export const SEASON_TABS_DATA = {
  [Season.FALL]: {
    mode: 0,
    icon: <FallIcon />,
    title: Season.FALL,
    subtitle: 'September-November',
  },
  [Season.WINTER]: {
    mode: 1,
    icon: <WinterIcon />,
    title: Season.WINTER,
    subtitle: 'December-February',
  },
  [Season.SPRING]: {
    mode: 2,
    icon: <SpringIcon />,
    title: Season.SPRING,
    subtitle: 'March-May',
  },
  [Season.SUMMER]: {
    mode: 3,
    icon: <SummerIcon />,
    title: Season.SUMMER,
    subtitle: 'June-July',
  },
};

export const SEASON_TITLE_BY_MODE: Record<number, Season> = {
  0: Season.FALL,
  1: Season.WINTER,
  2: Season.SPRING,
  3: Season.SUMMER,
};

export const TASK_STATUS_NOTIFICATION_DATA = {
  [TaskStatus.ACTIVE]: {
    title: 'Task Created',
    message: '',
    severity: 'success' as Severity,
  },
  [TaskStatus.COMPLETED]: {
    title: 'Task Completed',
    message: 'Way to go!',
    severity: 'success' as Severity,
  },
  Deleted: {
    title: 'Task was successfully removed',
    message: 'You can restore task from Removed if needed.',
    severity: 'success' as Severity,
  },
};

export const GRADE = {
  EIGHTH: {
    label: '8th',
    value: 8,
  },
  NINTH: {
    label: '9th',
    value: 9,
  },
  TENTH: {
    label: '10th',
    value: 10,
  },
  ELEVENTH: {
    label: '11th',
    value: 11,
  },
  TWELFTH: {
    label: '12th',
    value: 12,
  },
};

export const ALL_GRADES_VALUE = 0;

export const VALID_GRADES = [9, 10, 11, 12];

export const ATTENDING_YEAR_BY_GRADE = {
  [GRADE.EIGHTH.value]: ATTENDING_YEAR + 4,
  [GRADE.NINTH.value]: ATTENDING_YEAR + 3,
  [GRADE.TENTH.value]: ATTENDING_YEAR + 2,
  [GRADE.ELEVENTH.value]: ATTENDING_YEAR + 1,
  [GRADE.TWELFTH.value]: ATTENDING_YEAR,
};

export const ARCHIVE_PAGE_TITLES = {
  title: 'Your task archive',
  subtitle: 'From this page, you can restore all pre-defined tasks you have previously removed.',
  emptyListSubtitle: 'Hmmm. Looks like you don’t have any tasks in your check list matching the selected filter.',
};

export const PLANNER_TIMELINE_TITLES = {
  subtitle:
    'Customize your tasks, follow the deadlines, discuss your plan with counselor and earn your points on the plan line. Applying to a school? Add the school to your application tracker to manage your application.',
  emptyListSubtitle:
    "Looks like you don't have any tasks added to your checklist. You can restore a default task from the archive or add a custom task to get started.",
};
