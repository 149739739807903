import { CoreTypography, CoreDialog } from '@youscience/khaleesi';

import DialogFooter from '@components/DialogFooter';

import { sxStyles, StyledDialogContent } from './ShareInfo.styles';

interface ShareInfoProps {
  isOpenShareInfo: boolean;
  onCloseShareInfo: () => void;
  onOpenPreviousStep?: () => void;
}

export const ShareInfo = (props: ShareInfoProps) => {
  const { isOpenShareInfo, onCloseShareInfo, onOpenPreviousStep } = props;

  const handleCloseShareInfo = () => {
    onCloseShareInfo();
    onOpenPreviousStep?.();
  };

  return (
    <CoreDialog sx={sxStyles.dialogWrap} open={isOpenShareInfo}>
      <StyledDialogContent>
        <CoreTypography variant="h3">Information we may share</CoreTypography>

        <CoreTypography sx={sxStyles.subtitle}>
          With your permission, this is a list of your information we may share with the colleges you select.
        </CoreTypography>

        <ul>
          <CoreTypography component="li">Aptitude, interest, and skill (certs) career and major fits</CoreTypography>

          <CoreTypography component="li">Preferences, saved careers, saved majors, recommended majors</CoreTypography>

          <CoreTypography component="li">Work-based learning experience</CoreTypography>

          <CoreTypography component="li">Resume, or parts/elements of the resume</CoreTypography>

          <CoreTypography component="li">Contact information (email, phone number, address)</CoreTypography>
        </ul>
      </StyledDialogContent>

      <DialogFooter goNextButtonTitle="Got it" onGoNext={handleCloseShareInfo} />
    </CoreDialog>
  );
};
